"use client";

import { motion } from "framer-motion";
import { ThemeLogo } from "./ThemeLogo";

export const Loading = () => (
  <motion.div
    initial="pageInitial"
    animate="pageAnimate"
    variants={{ pageInitial: { opacity: 0 }, pageAnimate: { opacity: 1 } }}
    className="w-screen h-screen absolute z-50  content-center flex items-center justify-center"
  >
    <ThemeLogo height={220} width={220} />
  </motion.div>
);
