import React from "react";
import { AvatarProps } from "./props";
import Image from "next/image";
import { cn } from "~/utils/tw";

const PlatformsAvatar: React.FC<AvatarProps> = ({
  imageUrl,
  size = "medium",
  className,
}) => {
  const sizeClasses = {
    small: "h-12 w-12",
    medium: "h-20 w-20",
    large: "h-[200px] w-[200px]",
  };

  return (
    <div className={cn(`relative flex ${sizeClasses[size]}`, className)}>
      <Image
        src={imageUrl}
        alt="Avatar"
        width={385}
        height={288}
        priority
        className="rounded-full object-cover"
        objectFit="cover"
      />
    </div>
  );
};

export default PlatformsAvatar;
