"use client";

import { Icon } from "@iconify/react";
import { FieldProps, FormContact1Props } from "../../../props";
import style from "../../form-contact.module.scss";
import {
  handleDefaltWhatsappMessage,
  phoneFormatter,
  removeNonNumericAndSpaces,
} from "~/utils";
import React, { useState } from "react";
import { RHFInputText } from "~/components/non-editable/hook-form/input-text";
import { usePathname } from "next/navigation";
import { SubmitHandler, useForm } from "react-hook-form";
import { setCookie } from "cookies-next";
import PlatformsToast from "~/components/non-editable/core/patforms-toast";
import { FormContactVariant02 } from "../02";

export const FormContactVariant01 = (props: FormContact1Props) => {
  const [toast, setToast] = useState<any>(null);
  const pathname = usePathname();
  const methods = useForm();
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = methods;

  const variationId = props?.variation?.currentVariationId || "01";
  const currentVariation = props?.variation?.variations?.find(
    (template: any) => template.id === variationId
  );

  const onSubmit: SubmitHandler<any> = async (data: any) => {
    setCookie("@user-datalayer", {
      fullName: data.name,
      email: data.email,
      phone: data.cellphone,
    });

    data.workflowId =
      currentVariation?.n8nWorkflowId?.value ?? props.n8nWorkflowId?.value;

    if (typeof window !== "undefined") {
      const protocol = window.location.protocol;
      const hostname = window.location.hostname;
      const port = window.location.port;

      data.originUrl = `${protocol}//${hostname}${
        port ? ":" + port : ""
      }${pathname}`;
    }

    setToast({
      message: "Dados enviados com sucesso! Aguarde nosso contado.",
      type: "success",
    });

    try {
      await fetch(
        `${process.env.BASE_ADDRESS}/api/workflow-basic-contact-form`,
        {
          mode: "no-cors",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
    } catch (error) {
      console.error(error);
    }
    reset();
    //todo: notificar ok
    setToast(null);
  };

  const formatedVendorWhatsAppPhone1 =
    currentVariation?.vendorWhatsAppPhone1?.value?.replace(/\D/g, "");
  const formatedVendorPhone1 = currentVariation?.vendorPhone1?.value?.replace(
    /\D/g,
    ""
  );
  const formattedAddress = currentVariation?.vendorFullAdress?.value?.replace(
    / /g,
    "+"
  );
  if (currentVariation.id === "02") {
    return <FormContactVariant02 {...props} />;
  }
  return (
    <div>
      <div className={`${style.container} container-${props.id}`}>
        <div className={`${style.wrapper} wrapper-${props.id}`}>
          <div className={`${style.content} content-${props.id}`}>
            <div
              className={`${style["content-wrapper"]} content-wrapper-${props.id}`}
            >
              <div
                className={`${style["title-wrapper"]} title-wrapper-${props.id}`}
              >
                <h1 className={`${style.title} title-${props.id}`}>
                  {typeof currentVariation?.formName?.value != "undefined"
                    ? currentVariation?.formName?.value
                    : ""}
                </h1>
                <svg
                  className={`${style["text-decorator"]} text-decorator-${props.id}`}
                  viewBox="30 2 150 4"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 6c39.898-3.018 133.755-7.244 190 0"
                    strokeWidth="4"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              {typeof currentVariation?.description?.value != "undefined" && (
                <div
                  className={style.description}
                  dangerouslySetInnerHTML={{
                    __html: currentVariation?.description?.value,
                  }}
                />
              )}
              <div className={`${style.item} item-${props.id}`}>
                {typeof currentVariation?.vendorFullAdress?.value !=
                  "undefined" && (
                  <div
                    className={`${style["item__wrapper"]} item__wrapper-${props.id}`}
                  >
                    <Icon
                      icon={"ep:location-information"}
                      className={`${style["item__wrapper__icon"]} item__wrapper__icon-${props.id}`}
                    />
                    <a
                      href={`https://www.google.com/maps/search/?api=1&query=${formattedAddress}`}
                      className="lg:m-1 xl:m-1"
                    >
                      {currentVariation?.vendorFullAdress?.value}
                    </a>
                  </div>
                )}

                {typeof currentVariation?.vendorPhone1?.value !=
                  "undefined" && (
                  <div
                    className={`${style["item__wrapper"]} item__wrapper-${props.id} ${style["item__wrapper--spaced"]} item__wrapper--spaced-${props.id}`}
                  >
                    <Icon
                      icon={"carbon:phone"}
                      className={`${style["item__wrapper__icon"]} item__wrapper__icon-${props.id}`}
                    />
                    <a
                      href={`tel:+55${removeNonNumericAndSpaces(
                        formatedVendorPhone1
                      )}`}
                      className="lg:m-1 xl:m-1 "
                      data-umami-event="Ligar Agora"
                    >
                      {phoneFormatter(currentVariation?.vendorPhone1?.value)}
                    </a>
                  </div>
                )}

                {typeof currentVariation?.vendorWhatsAppPhone1?.value !=
                  "undefined" && (
                  <div
                    className={`${style["item__wrapper"]} item__wrapper-${props.id} ${style["item__wrapper--spaced"]} item__wrapper--spaced-${props.id}`}
                  >
                    <Icon
                      icon={"ic:baseline-whatsapp"}
                      className={`${style["item__wrapper__icon"]} item__wrapper__icon-${props.id}`}
                    />
                    <a
                      className="flex gap-1 gtm_event_whatsapp"
                      href={`https://wa.me/+55${removeNonNumericAndSpaces(
                        formatedVendorWhatsAppPhone1
                      )}${handleDefaltWhatsappMessage()}`}
                      data-umami-event="Falar no Whatsapp"
                    >
                      Falar no Whatsapp
                    </a>
                  </div>
                )}

                {typeof currentVariation?.vendorEmail?.value != "undefined" && (
                  <div
                    className={`${style["item__wrapper"]} item__wrapper-${props.id} ${style["item__wrapper--spaced"]} item__wrapper--spaced-${props.id}`}
                  >
                    <Icon
                      icon={"material-symbols:alternate-email"}
                      className={`${style["item__wrapper__icon"]} item__wrapper__icon-${props.id}`}
                    />
                    <a
                      href={`mailto:${currentVariation?.vendorEmail?.value}`}
                      className="lg:m-1 xl:m-1"
                    >
                      {currentVariation?.vendorEmail?.value}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={`${style.form} form-${props.id}`}>
            <div
              className={`${style["form__wrapper"]} form__wrapper-${props.id}`}
            >
              <form
                className={`${style["form__wrapper__content"]} form__wrapper__content-${props.id}`}
                onSubmit={(e) => {
                  clearErrors();
                  handleSubmit(onSubmit)(e);
                }}
              >
                <div
                  className={`${style["form__item"]} form__item-${props.id}`}
                >
                  {currentVariation &&
                    currentVariation.fields?.value.map(
                      (item: FieldProps, index: number) => (
                        <React.Fragment key={item.name + index + Math.random()}>
                          <RHFInputText
                            placeholder={`Digite seu ${
                              item.name === "cellphone" ? "telefone" : item.name
                            }`}
                            key={item.name}
                            type={item.type}
                            name={item.name}
                            label={item.label}
                            register={register}
                            props={{
                              required: item.required,
                              minLength: item.minLength as number,
                              maxLength: item.maxLength as number,
                            }}
                          />

                          {errors[item.name] && (
                            <span
                              key={item.name}
                              className={`${style["form__error-text"]} form__error-text-${props.id}`}
                            >
                              {errors[item.name]?.message as string}
                            </span>
                          )}
                        </React.Fragment>
                      )
                    )}
                </div>
                <button
                  style={{ boxShadow: "0 3px 4px rgba(0, 0, 0, 0.672)" }}
                  className={`${style["variant2__form__card__button"]} variant2__form__card__button-${props.id}`}
                >
                  Enviar
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 ml-1"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <line x1="5" y1="12" x2="19" y2="12" />
                    <polyline points="12 5 19 12 12 19" />
                  </svg>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      {toast && <PlatformsToast message={toast.message} type={toast.type} />}
    </div>
  );
};
