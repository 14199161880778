"use client";

import { motion, AnimatePresence, useInView } from "framer-motion";
import { useState, useRef } from "react";
import { BrandNewVehicleCategory } from "../props";
import VehicleDetailsTable from "../vehicle-details-table";
import { TabName } from "./props";

import "../../../../style.css";
import { Vehicle } from "~/types";

export const DetailsTabs = ({ vehicle }: { vehicle: Vehicle }) => {
  const [activeTab, setActiveTab] = useState<TabName>("Geral");
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const getVehicleCategories = (
    key: keyof BrandNewVehicleCategory,
    value: string
  ): BrandNewVehicleCategory | null =>
    vehicle.vehicleZeroKMOutput?.vehicleSpecification?.vehicleCategories?.find(
      (i) => i[key] === value
    ) || null;

  const categories: Record<TabName, BrandNewVehicleCategory | null> = {
    Geral: getVehicleCategories("name", "Motor / Desempenho / Consumo"),
    Conforto: getVehicleCategories("name", "Conforto e conveniência"),
    Segurança: getVehicleCategories("name", "Segurança"),
    Tecnologia: getVehicleCategories("name", "Tecnologia / Conectividade"),
    Transmissão: getVehicleCategories("name", "Transmissão"),
    Carroceria: getVehicleCategories("name", "Carroceria"),
    Condução: getVehicleCategories("name", "Freios / Suspensão / Direção"),
    Temas: getVehicleCategories("name", "Temas"),
    Visibilidade: getVehicleCategories("name", "Visibilidade / iluminação"),
    Dimensões: getVehicleCategories("name", "Dimensões e Capacidades"),
    Assentos: getVehicleCategories("name", "Assentos"),
    Acabamento: getVehicleCategories("name", "Acabamento / Detalhes"),
    ["Rodas e Pneus"]: getVehicleCategories("name", "Rodas e Pneus"),
    Garantia: getVehicleCategories("name", "Garantia"),
    Observações: getVehicleCategories("name", "Informações adicionais"),
  };

  return (
    <motion.div
      id="technical-sheet"
      ref={ref}
      initial={{ opacity: 0, x: 200 }}
      animate={isInView ? { opacity: 1, x: 0 } : {}}
      transition={{ duration: 1 }}
      className="max-w-[1020px] mx-auto py-10 px-3 md:px-0"
    >
      <div className="mb-4 flex items-center overflow-auto scroll-container">
        <div className="flex gap-4">
          {(Object.keys(categories) as TabName[]).map((tab) => (
            <button
              key={tab}
              className={`py-1 mb-2 font-medium whitespace-nowrap ${
                activeTab === tab
                  ? "text-primary-600 border-b-[2px] border-primary-600"
                  : "text-gray-500"
              }`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>

      <AnimatePresence mode="wait">
        <motion.div
          key={activeTab}
          initial={{ opacity: 0, y: 20 }}
          animate={isInView ? { opacity: 1, y: 0 } : {}}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
        >
          {categories[activeTab] && (
            <VehicleDetailsTable
              categoryName={categories[activeTab]!.name}
              items={categories[activeTab]!.vehicleItems}
            />
          )}
        </motion.div>
      </AnimatePresence>
    </motion.div>
  );
};
