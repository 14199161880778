import DataLayerForm from "../../../../datalayer-form";
import { X } from "lucide-react";
import { ModalFormProps } from "./props";

export const ModalForm = ({
  open,
  setOpen,
  props,
  vehicle,
}: ModalFormProps) => (
  <>
    {open && (
      <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-stone-300/20 backdrop-blur-[2px] z-50">
        <section className="rounded-lg bg-white p-10 pb-8 pt-12 relative min-w-[350px] md:min-w-[500px]">
          <button
            className="border-none bg-transparent absolute top-4 right-4"
            onClick={() => setOpen(false)}
          >
            <X strokeWidth={1.5} />
          </button>
          <DataLayerForm
            props={props}
            variant={1}
            vehicle={vehicle}
            vehiclePrice={Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(vehicle.price)}
          />
        </section>
      </div>
    )}
  </>
);
