"use client";

import { Cta1Props } from "./props";
import { handleFormatStyleString, removeHtmlTagsFromText } from "~/utils";

import { usePathname } from "next/navigation";
import useDeviceType from "~/hooks/useDeviceType";
import { BackButton } from "./back-button";
import PlatformsAvatar from "~/components/non-editable/core/platforms-avatar";
import { Instagram } from "lucide-react";
import Link from "next/link";
import { TextGenerateEffect } from "~/components/non-editable/ui/text-effects/text-appearing";
import { OldCta1 } from "./old-cta1";

export function Cta1(props: Cta1Props): JSX.Element {
  const pathname = usePathname();

  const { deviceType, isMobile } = useDeviceType();

  const isDetails = pathname?.includes(":") ?? false;

  const hasVehicle =
    pathname?.includes(":") && Object.keys(props.data).length === 0
      ? false
      : true;

  const vendorName =
    props &&
    typeof props.vendorName != "undefined" &&
    typeof props.vendorName === "string"
      ? props.vendorName
      : //@ts-ignore
        (props.vendorName.value as string);

  const isDesktop = deviceType === "desktop";

  const profileImage = props.image.value ?? undefined;
  const diceBearImage = `https://api.dicebear.com/9.x/initials/png?seed=${
    vendorName.includes(" ")
      ? vendorName?.split(" ")[0]
      : vendorName ?? "Desconhecido"
  }&fontFamily=Tahoma&chars=1&backgroundColor=fbbf24`;

  const bannerImage = null;

  if (!hasVehicle) return <></>;

  if (isDetails)
    return (
      <BackButton
        referer={props.data.page.navigation.referer}
        customDomain={props.data.site.customDomain}
        subdomain={props.data.site.subdomain}
      />
    );

  if (isMobile && !isDetails) {
    return <OldCta1 {...props} />;
  }

  return (
    <>
      {props && props.styles && props.styles.value && (
        <style>
          {handleFormatStyleString(props.styles.value as string, props.id)}
        </style>
      )}
      <section id="containerCta1" className={`container-${props.id} w-full`}>
        <div className="w-full max-w-[1024px] mx-auto ">
          <div
            className={`h-[300px] w-full rounded-xl bg-cover bg-center relative ${
              !bannerImage
                ? "bg-gradient-to-tr from-primary-500 via-primary-600 to-primary-800"
                : ""
            }`}
            style={{
              backgroundImage: bannerImage ? `url('${bannerImage}')` : "",
            }}
          >
            <div className="absolute inset-0 bg-black opacity-40 rounded-xl" />

            <div className="absolute inset-0 flex justify-center items-center">
              <TextGenerateEffect
                words={removeHtmlTagsFromText(
                  String(props.description?.value ?? props.description)
                )}
                className="!text-white text-lg md:text-xl !text-center max-w-[600px] -mt-20 md:-mt-6"
              />
            </div>
          </div>
          <div className="relative w-full">
            <div
              className={`p-1 border-[1px] border-primary-800 rounded-full bg-white absolute -top-[110px] ${
                isDesktop ? "left-10" : "left-1/2 -translate-x-1/2"
              }`}
            >
              <PlatformsAvatar
                className=""
                size="large"
                imageUrl={
                  (profileImage ? profileImage : diceBearImage) as string
                }
              />
            </div>
          </div>
          <div
            className={`w-full relative ${
              isDesktop
                ? "pl-56 pt-4"
                : "flex flex-col items-center justify-center pt-[110px]"
            }`}
          >
            <h2 className={"text-[25px] font-bold"}>{vendorName}</h2>
            <p className="text-[18px] text-center md:text-start">
              {props.shortDescription.value}
            </p>
            <Link
              rel="nofollow no-reference"
              target="_blank"
              href={props.instagramUrl.value as string}
              className="absolute right-2 top-[10px] lg:top-[25px]"
            >
              <Instagram strokeWidth={1.5} />
            </Link>
          </div>
          <div className="w-full px-3 md:px-0">
            <div className="w-full h-[1px] mx-auto rounded-full bg-stone-300 mt-10" />
          </div>
        </div>
      </section>
    </>
  );
}
