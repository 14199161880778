"use client";

import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Icon } from "@iconify/react";

import { filterItemsOptions } from "../../options";
import FilterChips from "../../filter-chips";

import styles from "./client-filter-variant-01.module.scss";
import { Vehicle } from "~/types";
import { ClientFilterVariantProps } from "../../../props";
import OrderComponent from "../../order";
import { SlidersHorizontal } from "lucide-react";

export default function ClientFilterVariant1({
  children,
  props,
  handleFilter,
}: ClientFilterVariantProps) {
  const vehicles =
    props.data && props.data?.asideList ? props.data?.asideList : [];
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState<boolean>(false);

  const isPerfilClick = props.data.site.subdomain.includes("pc-");

  function handleMobileMenu() {
    setMobileFiltersOpen((prev) => !prev);
  }

  return (
    <div className={`${styles.container} container-${props.id}`}>
      <div
        className={`${styles.wrapper} wrapper-${props.id} ${
          isPerfilClick ? "max-w-[1020px]" : "max-w-[1500px]"
        }`}
      >
        <h1 className="md:mt-10">Estoque</h1>
        <div className={`${styles.row} row-${props.id}`}>
          <div
            className={`${styles["order-wrapper"]} order-wrapper-${props.id}`}
          >
            {props.options &&
              Boolean(
                props.options.showSort?.value ?? props.options.showSort
              ) == true && <OrderComponent props={props} />}

            {isPerfilClick ? (
              <button
                className={`${styles.button} !flex !flex-row-reverse`}
                onClick={handleMobileMenu}
              >
                Filtrar
                <SlidersHorizontal color="black" size={20} />
              </button>
            ) : (
              <Icon
                icon="mdi:filter"
                className={`${styles["mobile-icon"]} mobile-icon-${props.id}`}
                onClick={handleMobileMenu}
                height={24}
                name="mobile menu"
                width={24}
              />
            )}
          </div>
        </div>

        <Transition.Root show={mobileFiltersOpen} as={Fragment}>
          <Dialog
            as="div"
            style={{ zIndex: 999 }}
            className="relative z-50"
            onClose={handleMobileMenu}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 flex z-40">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="dark:bg-stone-900 w-full lg:w-[50%] bg-white ml-auto relative z-50 h-full shadow-xl py-4 pb-12 flex flex-col overflow-y-auto">
                  <div className="px-4 flex items-center justify-between">
                    <h2 className="text-xl font-medium ">Filtros</h2>
                    <button
                      type="button"
                      className="-mr-2 w-10 h-10 p-2 rounded-sm flex items-center justify-center text-primary-900 dark:text-primary-50"
                      onClick={handleMobileMenu}
                    >
                      <span className="sr-only">Close menu</span>
                      <Icon icon="material-symbols:close" width={30} />
                    </button>
                  </div>

                  <form className="mt-4 border-t border-primary-400">
                    {filterItemsOptions.map(
                      ({
                        key,
                        queryKey,
                        title,
                        useListByKeys,
                        visibleProp,
                        Component,
                        isKm,
                      }) => {
                        const visible =
                          // @ts-ignore
                          (props.options?.[visibleProp]?.value ||
                            // @ts-ignore
                            props.options?.[visibleProp]) ??
                          true;

                        if (
                          !props.options.showYearFilter &&
                          (key.includes("manufactureYear") ||
                            key.includes("modelYear"))
                        )
                          return;

                        return (
                          <Component
                            key={String(key)}
                            isKm={isKm}
                            handleFilter={handleFilter}
                            itemKey={key as keyof Vehicle}
                            //@ts-ignore
                            queryKey={queryKey}
                            title={title}
                            props={props}
                            vehicles={vehicles}
                            showOption={visible}
                            useListByKey={useListByKeys}
                          />
                        );
                      }
                    )}
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <FilterChips handleFilter={handleFilter} />

        <div
          className={`${
            isPerfilClick ? "" : styles["filter-wrapper"]
          } filter-wrapper-${props.id}`}
        >
          {!isPerfilClick && (
            <aside
              className={`${styles["filter-content"]} filter-content-${
                props.id
              } ${
                props.options &&
                Boolean(
                  props.options.showMakeFilter?.value ??
                    props.options.showMakeFilter
                ) == false &&
                Boolean(
                  props.options.showModelFilter?.value ??
                    props.options.showModelFilter
                ) == false &&
                styles["filter-content--hidden"]
              }`}
            >
              {filterItemsOptions.map(
                ({
                  key,
                  queryKey,
                  title,
                  useListByKeys,
                  visibleProp,
                  Component,
                  isKm,
                }) => {
                  const visible =
                    // @ts-ignore
                    (props.options?.[visibleProp]?.value ||
                      // @ts-ignore
                      props.options?.[visibleProp]) ??
                    true;

                  if (
                    !props.options.showYearFilter &&
                    (key.includes("manufactureYear") ||
                      key.includes("modelYear"))
                  )
                    return;

                  return (
                    <Component
                      key={String(key)}
                      isKm={isKm}
                      handleFilter={handleFilter}
                      itemKey={key as keyof Vehicle}
                      //@ts-ignore
                      queryKey={queryKey}
                      props={props}
                      title={title}
                      vehicles={vehicles}
                      // Caso o mdx possua uma configuração para mostrar/não mostrar um certo filtro, respeitamos, caso não, mostramos por padrão o filtro
                      showOption={visible}
                      useListByKey={useListByKeys}
                    />
                  );
                }
              )}
            </aside>
          )}
          {children}
        </div>
      </div>
    </div>
  );
}
