import { PlatformsIcon } from "~/components/non-editable/core";
import { Cta1Props } from "../props";
import styles from "./mobile-menu.module.scss";
import { motion } from "framer-motion";
import { HeaderMenuMobile } from "./header-mobile-menu";
import { WhatsappAction } from "./whatsapp-action";
import { DevelopedBy } from "~/components/editable/developed-by";
import { useState } from "react";
import { ActionCall } from "./call-action";

export interface MobileMenuProps {
  ctaProps: Cta1Props;
  open: boolean;
  setOpen: (state: boolean) => void;
}

export const MobileMenu = ({ ctaProps, open, setOpen }: MobileMenuProps) => {
  const [openCall, setOpenCall] = useState<boolean>(false);
  const [openWhatsappForm, setOpenWhatsappForm] = useState<boolean>(false);

  return (
    <>
      {open && (
        <section className={`${styles.overlay} overlay-${ctaProps.id}`}>
          <motion.div
            initial={{ bottom: "-500px", minHeight: "25%", position: "fixed" }}
            animate={{
              bottom: open ? "0px" : "-500px",
              minHeight: openWhatsappForm ? "100vh" : "25%",
              borderTopLeftRadius: openWhatsappForm ? "1px" : "",
              borderTopRightRadius: openWhatsappForm ? "1px" : "",
              position: openWhatsappForm ? "unset" : "fixed",
            }}
            transition={{ duration: 0.2 }}
            className={`${styles["menu-container"]} menu-container-${ctaProps.id}`}
          >
            <motion.div
              initial={{ display: "unset" }}
              animate={{
                opacity: openWhatsappForm ? 0 : 1,
                display: openWhatsappForm ? "none" : "",
              }}
              transition={{ duration: 0.2 }}
            >
              <HeaderMenuMobile ctaProps={ctaProps} setOpen={setOpen} />
            </motion.div>

            <div className={styles.separator} />
            <motion.div
              initial={{ gap: "10px" }}
              animate={{ gap: openWhatsappForm ? "0px" : "10px" }}
              transition={{ duration: 0.2 }}
              className={`${styles["container-actions"]} container-actions-${ctaProps.id}`}
            >
              {typeof ctaProps.vendorPhone != "undefined" && (
                <div style={{ width: "100%" }}>
                  <motion.div
                    initial={{ opacity: 1 }}
                    animate={{
                      opacity: openWhatsappForm ? 0 : 1,
                    }}
                    transition={{ duration: openWhatsappForm ? 0.2 : 0.5 }}
                    style={{ width: "100%" }}
                  >
                    <motion.button
                      onClick={() => setOpenCall(true)}
                      className={`${styles.button} button-${ctaProps.id} gtm_event_whatsapp`}
                      initial={{
                        opacity: 1,
                        height: "",
                      }}
                      animate={{
                        opacity: openWhatsappForm ? 0 : 1,
                        height: openWhatsappForm ? "15px" : "",
                        overflow: openWhatsappForm ? "hidden" : "visible",
                      }}
                      transition={{ duration: !openWhatsappForm ? 0.2 : 0.5 }}
                      style={{ width: "100% !important" }}
                    >
                      <PlatformsIcon
                        width={25}
                        height={25}
                        className={`${styles.icon} icon-${ctaProps.id}`}
                        icon="ph:phone"
                      />
                      Ligar Agora
                    </motion.button>
                  </motion.div>
                  {openCall && (
                    <ActionCall
                      actionType="call"
                      ctaProps={ctaProps}
                      openWhatsappForm={openCall}
                      setOpenWhatsappForm={setOpenCall}
                    />
                  )}
                </div>
              )}

              <WhatsappAction
                openWhatsappForm={openWhatsappForm}
                setOpenWhatsappForm={setOpenWhatsappForm}
                ctaProps={ctaProps}
                actionType="whatsapp"
              />

              <DevelopedBy paddingBottom="0px" />
            </motion.div>
          </motion.div>
        </section>
      )}
    </>
  );
};
