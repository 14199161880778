import { useEffect, useState } from "react";

const useDeviceType = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [deviceType, setDeviceType] = useState<string>("desktop");

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      const isWindowMobile = width < 768;
      setIsMobile(isWindowMobile);

      if (isWindowMobile) {
        setDeviceType("mobile");
      } else if (width >= 768 && width < 1024) {
        setDeviceType("tablet");
      } else {
        setDeviceType("desktop");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return { isMobile, deviceType };
};

export default useDeviceType;
