export const CustomHyundaiStyles = () => (
  <style jsx global>
    {`
      .fslightboxs {
        border-radius: var(--border-lg) !important;
      }
      .fslightbox-slide-btn {
        border-radius: var(--border-lg) !important;
      }
      .fslightbox-toolbar {
        border-bottom-left-radius: var(--border-lg) !important;
      }

      #buttonCta1 {
        background-color: var(--primary-800) !important;
        border-radius: var(--border-lg) !important;
      }
      #buttonCta1:hover {
        background-color: var(--primary-500) !important;
      }

      #containerCta1 {
        padding: 0;
      }
    `}
  </style>
);
