/* eslint-disable react/prop-types */
import { Vehicle } from "~/types";
import Link from "next/link";
import { PlatformsImage } from "~/components/non-editable/core";
import { ModalForm } from "../form";
import { useState } from "react";
import { InventoryProps } from "~/components/editable/inventory/vehicles/mock";
import { vehicleLinkBuilderByReference } from "~/utils";

export const OthersVersions = ({
  vehicleList,
  vehicle,
  showPrice,
  props,
}: {
  vehicleList?: Vehicle[];
  vehicle: Vehicle;
  showPrice?: { value: boolean };
  props: InventoryProps;
}): JSX.Element | null => {
  const [open, setOpen] = useState<boolean>(false);

  const vehiclesWithSameModel = vehicleList?.filter(
    (v) => v.model === vehicle.model && v.version !== vehicle.version
  );

  const showPriceOnUI = showPrice?.value ?? true;

  if (!vehicleList || vehiclesWithSameModel?.length === 0) return null;

  return (
    <div className="max-w-[1020px] px-3 md:px-0 py-10 lg:py-14 mx-auto bg-transparent">
      <div className="max-w-[1500px] mx-auto mb-4 lg:mb-6">
        <div className="flex gap-2 items-center">
          <p className="text-[#8b8b8b]">
            <span className="underline">Out</span>ras Versões
          </p>
        </div>
        <h2 className="text-2xl font-bold md:text-2xl md:leading-tight text-black">
          Encontre agora seu {vehicle.model} novo
        </h2>
      </div>

      <div className="flex gap-5 overflow-x-auto scroll-smooth snap-x snap-mandatory pb-5">
        {vehiclesWithSameModel?.map((vehicle, index) => {
          const slug =
            vehicleLinkBuilderByReference({
              vehicle,
              site: {
                subdomain: props.data.site.subdomain,
                customDomain: props.data.site?.customDomain ?? "",
                preferences: props.data.site?.preferences ?? [],
              },
              region: props.data.site.preferences.find(
                (i) => i.type === "localSeoSlug"
              )?.value,
            }) ?? "/";

          return (
            <div
              key={`vehicle-${vehicle.id}-${index}`}
              className="border-[1px] border-[#ccc] min-w-[16rem] snap-start overflow-hidden"
            >
              <Link href={slug} className="block border-b-[1px] border-[#ccc]">
                <PlatformsImage
                  width={500}
                  height={500}
                  layout="responsive"
                  src={vehicle.images.split(",")[0]}
                  alt={`Imagem principal do veículo`}
                  className="block"
                />
              </Link>

              <div className="p-5 text-gray-700">
                <Link href={slug} className="block">
                  <p className="font-bold">
                    {vehicle.make}{" "}
                    <span className="text-primary-500 font-extrabold">
                      {vehicle.model}
                    </span>
                  </p>
                </Link>
                <p className="text-sm truncate">{vehicle.version}</p>
                {showPriceOnUI && (
                  <p className="font-bold text-primary-500 mt-5">
                    R${" "}
                    <span className="text-gray-700">
                      {vehicle.price.toLocaleString("pt-br", {
                        minimumFractionDigits: 2,
                      })}
                    </span>
                  </p>
                )}
                <div className="w-full flex items-center pt-5">
                  <button
                    type="button"
                    onClick={() => setOpen(true)}
                    className="contact-button w-full bg-primary-800 rounded-lg px-2 py-1 text-white text-sm font-semibold"
                  >
                    Falar agora
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {props && (
        <ModalForm
          open={open}
          props={props}
          setOpen={setOpen}
          vehicle={vehicle}
        />
      )}
    </div>
  );
};
