import Link from "next/link";
import { Download, Eye } from "lucide-react";
import { DownloadsProps } from "./props";

export const Downloads = ({ files }: DownloadsProps) => (
  <section id="downloads">
    <div className="max-w-[1020px] mx-auto px-3 md:px-0">
      <h5 className=" text-[#8b8b8b] mb-4 text-xl">
        <span className="underline">Down</span>loads
      </h5>
      <div className="flex flex-col space-y-1 max-w-[90%] sm:max-w-[80%] md:max-w-[50%]">
        {files &&
          files.map((file, index) => {
            const linkForDownload = file.url.replace("?wrap=0", "?download=1");
            return (
              <Link
                key={`file-${index}-${file.title}-${file.fileExtension}`}
                download={file.fileName}
                href={file.url}
                target="_blank"
                className="flex items-center justify-between text-lg md:text-sm text-gray-500 hover:underline"
              >
                <span className="flex-1">{file.title}</span>
                <div className="ml-2 flex gap-4 items-center">
                  <div>
                    <Link
                      key={`file-${index}-${file.title}-${file.fileExtension}`}
                      download={file.fileName}
                      href={file.url}
                      target="_blank"
                      className="flex items-center justify-between text-lg md:text-sm text-gray-500 hover:underline"
                    >
                      <Eye
                        className="hover:text-primary-500 cursor-pointer"
                        size={24}
                        strokeWidth={1.5}
                      />
                    </Link>
                  </div>
                  <div className="pb-1">
                    <Link
                      key={`file-${index}-${file.title}-${file.fileExtension}`}
                      download={file.fileName}
                      href={linkForDownload}
                      className="flex items-center justify-between text-lg md:text-sm text-gray-500 hover:underline"
                    >
                      <Download
                        className="hover:text-primary-500 cursor-pointer"
                        size={24}
                        strokeWidth={1.5}
                      />
                    </Link>
                  </div>
                </div>
              </Link>
            );
          })}
      </div>
    </div>
  </section>
);
