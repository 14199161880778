"use client";

import Link from "next/link";
import { ArrowLeft } from "lucide-react";
import { BackButtonProps } from "./props";

export const BackButton = ({
  referer,
  customDomain,
  subdomain,
}: BackButtonProps) => {
  const href = (() => {
    if (!referer) return "/";

    try {
      const host = new URL(referer).host.toLowerCase();
      const domain = customDomain?.toLowerCase();
      const sub = subdomain?.toLowerCase();

      if ((domain && host.includes(domain)) || (sub && host.includes(sub))) {
        return referer;
      }
    } catch {
      return referer;
    }

    return "/";
  })();

  return (
    <div className="w-full px-3 pt-10">
      <Link
        href={href}
        className="flex gap-4 items-center w-fit hover:underline pr-2"
      >
        <ArrowLeft />
        <span className="font-semibold text-lg">Voltar</span>
      </Link>
    </div>
  );
};
