import styles from "./chip.module.scss";
import { ChipProps } from "./props";

export default function Chip({
  children,
  stroke,
  textOnly,
  usePaper,
  useSmall,
}: ChipProps) {
  return (
    <div
      className={`${styles.chip} chip ${stroke ? styles["chip--stroke"] : ""} ${
        textOnly ? `${styles["chip--text-only"]} !rounded-lg !p-1` : ""
      } ${usePaper ? styles["chip--paper"] : ""} ${
        useSmall ? styles["chip--small"] : ""
      }`}
    >
      {children}
    </div>
  );
}
