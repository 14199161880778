import { ArrowRight, UsersRound } from "lucide-react";
import Image from "next/image";
import { Vehicle } from "~/types";

interface HyundayHeroProps {
  vehicle: Vehicle;
  externalImages: string[];
  setOpen(value: boolean): void;
}

export const HyundayHero = ({
  vehicle,
  externalImages,
  setOpen,
}: HyundayHeroProps) => (
  <div className="w-full relative min-h-screen bg-gradient-to-t from-white to-secondary-500">
    <div className="bg-secondary-500 min-h-screen w-full absolute -top-64 -z-10" />
    <div className="max-w-[1020px] mx-auto px-3 pt-10 md:flex">
      <div className="w-full md:w-[40%] md:pt-40 md:flex md:flex-col">
        <div>
          <div className="flex gap-2 items-center">
            <UsersRound color="#8b8b8b" />
            <p className="text-[#8b8b8b]">
              <span className="underline">Fam</span>ília
            </p>
          </div>
          <h2 className="text-2xl font-bold md:text-2xl md:leading-tight text-black">
            Seja {vehicle.make}
          </h2>
          <p className="text-black">
            Temos um {vehicle.make} {vehicle.model} para tudo que você precisa.
          </p>
        </div>
        <div className="mt-10 max-w-72 hidden md:block">
          <Image
            src={externalImages[0]}
            alt={`Imagem do veículo ${vehicle.make} ${vehicle.model} - ${vehicle.version}`}
            width={800}
            height={600}
            className="w-full max-w-4xl rounded-lg shadow-lg"
          />
        </div>
      </div>
      <div className="md:w-[60%] flex flex-col justify-center">
        <div className="w-full flex justify-end">
          <div className="mt-10 md:max-w-[75%]">
            <Image
              src={externalImages[1]}
              alt={`Imagem do veículo ${vehicle.make} ${vehicle.model} - ${vehicle.version}`}
              width={800}
              height={600}
              className="w-full max-w-3xl rounded-lg shadow-lg"
            />
          </div>
        </div>
        <div className="flex flex-col justify-center items-center md:justify-end md:items-end py-10">
          <h2 className="text-2xl font-bold md:text-2xl md:leading-tight text-black max-w-[450px] mb-5 text-center md:text-inherit">
            Aproveite condições exclusivas na compra do {vehicle.make}{" "}
            {vehicle.model}!
          </h2>
          <p className="text-black max-w-[450px] text-center md:text-end">
            O {vehicle.make} {vehicle.model} é um {vehicle.category} com{" "}
            {vehicle.doors} portas, movido a {vehicle.fuel}, ideal para quem
            busca conforto e eficiência.
          </p>
          <div className="max-w-[450px] w-full items-center md:items-end mt-20">
            <button
              onClick={() => setOpen(true)}
              className="px-6 py-3 w-full bg-primary-800 text-white font-bold rounded-lg shadow-md hover:bg-primary-700 transition-colors flex items-center justify-center"
            >
              Solicitar Cotação <ArrowRight className="ml-2" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
);
