/* eslint-disable no-constant-condition */
import { FiltrQueryParams, ImageLinks, Options, Vehicle } from "~/types";
import {
  FilterDefaultQueryKeys,
  InventoryOptionsProps,
  WebsitePreference,
  WebsitePreferenceType,
} from "..";
import slugify from "slugify";
import {
  extractVehicleDetailsFromPathname,
  toTitleCase,
} from "@sync/utils/src/platforms";

export function getVehicleImagesForGallery(str: string, isDesktop?: boolean) {
  const result: ImageLinks[] = [];
  const vehicleImages = str.split(",");

  if (vehicleImages.length > 0) {
    for (let i = 0; i < vehicleImages.length; i++) {
      const mobileLink =
        "https://cdn-images.sync.com.br" +
        (!isDesktop ? "/600x450/" : "/880x660/") +
        vehicleImages[i].split(".com/")[1];
      const webLink =
        "https://cdn-images.sync.com.br" +
        "/1440x1080/" +
        vehicleImages[i].split(".com/")[1];

      result.push({ webLink, mobileLink });
    }
  }
  return result;
}

export function removeHtmlTagsFromText(html: string) {
  return html.replace(/<[^>]*>/g, "");
}

export function stringToSlug(str: string) {
  if (str !== undefined) {
    str = str.replace(/^\s+|\s+$/g, "");
    str = str.toLowerCase();

    const from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    const to = "aaaaeeeeiiiioooouuuunc------";
    for (let i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
      .replace(/\s+/g, "-") // collapse whitespace and replace by -
      .replace(/-+/g, "-"); // collapse dashes
  }
  return str;
}

export function getSortOptions(options: InventoryOptionsProps) {
  const pricePreference =
    options == null
      ? true
      : options?.showSortByPrice?.value ?? options?.showSortByPrice;
  const KMPreference =
    options == null
      ? true
      : options.showSortByKm?.value ?? options.showSortByKm;
  const yearPreference =
    options == null
      ? true
      : options.showSortByYear?.value ?? options.showSortByYear;

  const result: Options[] = [];
  if (pricePreference) {
    result.push({ code: "price", name: "Menor Preço" });
    result.push({ code: ".price", name: "Maior Preço" });
  }
  if (KMPreference) {
    result.push({ code: "kilometers", name: "Menor km" });
    result.push({ code: ".kilometers", name: "Maior km" });
  }
  if (yearPreference) {
    result.push({ code: "modelYear", name: "Mais antigo" });
    result.push({ code: ".modelYear", name: "Mais novo" });
  }
  return result;
}

export function getPaginationOptions(itemsPerPage: number) {
  const result: Options[] = [];

  result.push({
    code: itemsPerPage.toString(),
    name: `${itemsPerPage.toString()} por página`,
  });

  result.push({
    code: (itemsPerPage * 2).toString(),
    name: `${(itemsPerPage * 2).toString()} por página`,
  });

  result.push({
    code: (itemsPerPage * 3).toString(),
    name: `${(itemsPerPage * 3).toString()} por página`,
  });

  return result;
}

export function urlPushUtility({
  router,
  category,
  make,
  model,
  minYear,
  maxYear,
  minPrice,
  maxPrice,
  search,
  minKm,
  maxKm,
  page,
  pagination,
  sort,
}: FiltrQueryParams) {
  const query = router.query;

  if (pagination) query.pagination = pagination;

  if (make) query.make = make;
  else query.make = "";

  if (model) query.model = model;
  else query.model = "";

  if (sort) query.sort = sort;
  else query.sort = "-createdAt";

  if (minPrice) query.minPrice = minPrice;
  else query.minPrice = "";

  if (maxPrice) query.maxPrice = maxPrice;
  else query.maxPrice = "";

  if (minKm) query.minKm = minKm;
  else query.minKm = "";

  if (minYear) query.minYear = minYear;
  else query.minYear = "";

  if (maxYear) query.maxYear = maxYear;
  else query.maxYear = "";

  if (maxKm) query.maxKm = maxKm;
  else query.maxKm = "";

  if (category) query.category = category;
  if (page) query.page = page;
  if (search) query.search = search;

  router.push({
    pathname: router.pathname.split("/").pop(),
    query,
  });
}

export function masktel(v: string) {
  v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
  v = v.replace(/^(\d{2})(\d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
  v = v.replace(/(\d)(\d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
  return v;
}

export function getVehicleInformation(currentPath: string) {
  const slug = currentPath
    ?.split(":")[0]
    ?.split("/")
    ?.pop()
    ?.split("-") as string[];
  const year = slug[slug.length - 1];

  let make = slug[0];
  let isLongMake;
  if (make == "kia" || make == "caoa" || make == "land" || make == "mercedes")
    isLongMake = true;

  if (isLongMake) make = slug[0] + "-" + slug[1];

  let model;
  if (slug.length > 3) {
    if (isLongMake) {
      model = slug[2];
    } else {
      model = slug[1] + "-" + slug[2];
    }
  } else {
    if (isLongMake) {
      model = slug[2];
    } else {
      model = slug[1];
    }
  }

  return {
    vehicle: {
      make: make,
      year: year,
      model: model,
    },
  };
}

interface AccGroupBy {
  [key: string]: Vehicle[];
}

export function groupBy(array: Vehicle[], key: keyof Vehicle) {
  return array?.reduce((acc: AccGroupBy, item: Vehicle) => {
    //@ts-ignore
    if (!acc[item[key]]) {
      //@ts-ignore
      acc[item[key]] = [];
    }
    //@ts-ignore
    acc[item[key]].push(item);
    return acc;
  }, {});
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: "asc" | "desc",
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function handleNormalizeString(item: string): string {
  return item
    ?.normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
}

export function handleNormalizeQueryString(item: string): string {
  return (
    item
      ?.normalize("NFD")
      //@ts-ignore
      .replaceAll(/[\u0300-\u036f]/g, "")
      .replaceAll(/-/g, " ")
      .replaceAll(" ", "")
      .replaceAll("/", "")
      .toLowerCase()
  );
}

export function phoneFormatter(num: string): string {
  if (num) {
    const number = num.replace(/\D/g, "");

    if (number.length === 10) {
      const formattedNumber = number.replace(
        /(\d{2})(\d{4})(\d{4})/,
        "($1) $2-$3"
      );
      return formattedNumber;
    } else if (number.length === 11) {
      const formattedNumber = number.replace(
        /(\d{2})(\d{5})(\d{4})/,
        "($1) $2-$3"
      );
      return formattedNumber;
    } else {
      return num;
    }
  }

  return "";
}

// https://cdn-images.sync.com.br/fit-in/1440x1080/filters:format(.avif)/7488ce63-7405-4de3-87f9-e8b5c64c4701.JPG
// /filters:sharpen(2,1.0,true)/filters:watermark(sync-platforms,8hfw1/assets/9CF5942C59G1.png,10,10,0.5)/

export function resetSyncCdnImageSize(
  imgUrls: string[],
  width: string,
  height: string,
  format?: string
) {
  let imagesResized: string[] = [];
  if (imgUrls.length > 0 && imgUrls?.[0]?.includes("cdn-images.sync.com.br")) {
    imgUrls.map((item) => {
      const arraySpplited = item.split("/");
      arraySpplited[3] = `${width}x${height}/`;
      // arraySpplited[3] = `${width}x${height}/filters:format(${
      //   format ? format : ".avif"
      // })`;
      imagesResized.push(arraySpplited.join("/"));
    });
  } else {
    imagesResized = imgUrls;
  }
  return imagesResized;
}

export const removeNonNumericAndSpaces = (inputString: string) => {
  if (typeof inputString !== "string") return "";

  const cleanedString = inputString?.replace(/[^\d]/g, "").replace(/\s/g, "");

  return cleanedString;
};

export const handleDefaltWhatsappMessage = (): string => {
  if (typeof window === "undefined") {
    return `?text=Oi%2C%20estou%20interessado%20em%20um%20ve%C3%ADculo%20que%20vi%20no%20seu%20site.%20Pode%20me%20fornecer%20mais%20informa%C3%A7%C3%B5es%3F`;
  }
  const localUrl = window.location.href ?? "";

  const url: string = localUrl?.split("://")[1] ?? "";

  const isDetails: boolean = url?.includes(":") ? true : false;

  switch (isDetails) {
    case true:
      return `?text=%20Ol%C3%A1,%20tenho%20interesse%20no%20ve%C3%ADculo%20do%20link:%20${localUrl}`;
    case false:
      return `?text=Oi%2C%20estou%20interessado%20em%20um%20ve%C3%ADculo%20que%20vi%20no%20seu%20site.%20Pode%20me%20fornecer%20mais%20informa%C3%A7%C3%B5es%3F`;
  }
};

export const handleFormatStyleString = (
  styleString: string | null | undefined,
  id: string,
  classes?: string[]
): string | undefined => {
  if (!styleString) return "";

  if (styleString.includes("<")) {
    const cleanedString = styleString.replace(/<[^>]*>/g, "");

    const styleParts = cleanedString
      .split("};")
      .filter((part) => part.trim() !== "");

    const formattedParts = styleParts.map((part) => {
      const [selectors, properties] = part.split("{");
      const formattedSelectors = selectors
        .split(",")
        .map((selector) => {
          const hasHover = /:hover/i.test(selector.trim());

          let formattedSelector = selector.trim().replace(/:hover/g, "");

          if (!/^[a-z]+$/i.test(formattedSelector)) {
            formattedSelector = `${formattedSelector}-${id}`;
          }

          if (hasHover) {
            formattedSelector = `${formattedSelector}:hover`;
          }

          return formattedSelector;
        })
        .join(", ");
      return `${formattedSelectors} {${properties}}`;
    });

    return formattedParts.join(" ");
  } else {
    let copyStylesString = styleString;

    if (classes && classes.length > 0) {
      for (let i = 0; i < (classes ?? []).length; i++) {
        if (styleString.includes(classes[i])) {
          copyStylesString = copyStylesString.replace(
            classes[i],
            `${classes[i]}-${id}`
          );
        }
      }
    }

    return copyStylesString.replace(/\n/g, "").replace(/;/g, "");
  }
};

export function replaceNewlinesWithSpaces(text: string) {
  if (!text) return "";
  return text.replace(/\n/g, " ");
}

export function maskPhone(value: string): string {
  if (!value) return "";

  let v = value.replace(/\D/g, ""); // Remove tudo o que não é dígito
  v = v.replace(/^(\d{2})(\d)/g, "($1) $2"); // Coloca parênteses em volta dos dois primeiros dígitos
  v = v.replace(/(\d)(\d{4})$/, "$1-$2"); // Coloca hífen entre o quarto e o quinto dígitos
  return v;
}

export function generateSmartSlug(site: string, vehicles: Vehicle[]) {
  const slugs: { site: string; slug: string[] }[] = [];

  vehicles.forEach((vehicle) => {
    const pageName = "estoque";
    const vehicleType = vehicle.type === "Car" ? "carros" : "motos";
    const citySlug = "sp-jundiai"; // Pode ser dinâmico se necessário

    const makeSlug = slugify(vehicle.make, { lower: true });
    const modelSlug = slugify(vehicle.model, { lower: true });
    const versionSlug = slugify(vehicle.version, { lower: true });
    const yearSlug = vehicle.modelYear.toString();

    // Gerar combinações de slugs
    slugs.push({ site, slug: [pageName, vehicleType, citySlug, makeSlug] });
    slugs.push({
      site,
      slug: [pageName, vehicleType, citySlug, makeSlug, yearSlug],
    });
    slugs.push({
      site,
      slug: [pageName, vehicleType, citySlug, `${makeSlug}-${modelSlug}`],
    });
    slugs.push({
      site,
      slug: [
        pageName,
        vehicleType,
        citySlug,
        `${makeSlug}-${modelSlug}`,
        yearSlug,
      ],
    });
    slugs.push({
      site,
      slug: [
        pageName,
        vehicleType,
        citySlug,
        `${makeSlug}-${modelSlug}-${versionSlug}`,
      ],
    });
    slugs.push({
      site,
      slug: [
        pageName,
        vehicleType,
        citySlug,
        `${makeSlug}-${modelSlug}-${versionSlug}`,
        yearSlug,
      ],
    });
  });

  return slugs;
}

export const handleInventoryType = (vehicleList: Vehicle[]): string => {
  if (!vehicleList || vehicleList.length === 0) return "/carros";

  const hasCar = vehicleList.some((v: Vehicle) => v.type === "Car");
  const hasMotorcycle = vehicleList.some(
    (v: Vehicle) => v.type === "Motocycle"
  );

  if (hasCar && hasMotorcycle) {
    return `/carros-e-motos`;
  } else if (hasCar) {
    return `/carros`;
  } else if (hasMotorcycle) {
    return `/motos`;
  }

  return "/carros";
};

export const extractRegionFromCurrentSlug = (
  currentSlug?: string[]
): string | undefined => {
  if (!currentSlug) return undefined;

  const pathname = currentSlug?.join("/") ?? "";

  const details = extractVehicleDetailsFromPathname(`/${pathname}`);

  const region = details?.region ?? undefined;

  return region;
};

export type LinkBuilderRef =
  | "make"
  | "model"
  | "version"
  | "category"
  | "year"
  | "region"
  | "style";

export const vehicleLinkBuilderByReference = ({
  ref,
  value,
  vehicle,
  vehicleList = [],
  site,
  region,
}: {
  ref?: LinkBuilderRef;
  value?: string;
  vehicle?: Vehicle;
  vehicleList?: Vehicle[];
  site?: {
    inventoryType?: string;
    subdomain: string;
    customDomain?: string;
    preferences: WebsitePreference[];
  };
  region?: string;
}) => {
  const preferences = site?.preferences ?? [];

  const inventorySlugPreference =
    preferences.find(
      (pref) => pref.type === WebsitePreferenceType.inventoryPathName
    )?.value ?? "estoque";

  const regionPreferences = preferences.filter(
    (pref) => pref.type === WebsitePreferenceType.localSeoSlug
  );
  const regions = regionPreferences.map((item) => item.value).join(",");

  let regionsArr = toTitleCase(regions).split(",");
  regionsArr = region
    ? [region]
    : regionsArr.filter((region) => region.trim() !== "");

  if (vehicle) {
    const vehicleType = vehicle.type === "Car" ? "carro" : "moto";

    return `/${slugify(inventorySlugPreference, {
      lower: true,
    })}/${vehicleType}/${slugify(regionsArr[0] ?? "brasil", {
      lower: true,
    })}/${slugify(
      `${vehicle.make ?? ""} ${vehicle.model ?? ""} ${vehicle.version ?? ""} ${
        vehicle.modelYear ?? ""
      } ${vehicle.transmission ?? ""} ${vehicle.color ?? ""}`,
      { lower: true }
    )}:${vehicle.id ? vehicle.id : vehicle.externalId}`;
  }

  if (ref === "make" && value) {
    const inventoryHasCar = vehicleList.some(
      (v) => v.type === "Car" && slugify(v.make) === slugify(value)
    );
    const inventoryHasMotorcycle = (vehicleList ?? []).some(
      (v) => v.type === "Motocycle" && slugify(v.make) === slugify(value)
    );

    const vehicleTypeSlug =
      inventoryHasCar && inventoryHasMotorcycle
        ? "carros-e-motos"
        : inventoryHasMotorcycle
        ? "motos"
        : "carros";

    return `/${slugify(inventorySlugPreference, {
      lower: true,
    })}/${vehicleTypeSlug}/${slugify(regionsArr[0] ?? "brasil", {
      lower: true,
    })}/${slugify(value, { lower: true })}`;
  }

  if (ref === "model" && value) {
    const vehiclesByModelRef = (vehicleList ?? []).filter(
      (v) => slugify(v.model) === slugify(value)
    );

    const isCarModel = vehiclesByModelRef.some((v) => v.type === "Car");
    const isMotorcycleModel = vehiclesByModelRef.some(
      (v) => v.type === "Motocycle"
    );

    const vehicleTypeSlug =
      isCarModel && isMotorcycleModel
        ? "carros-e-motos"
        : isMotorcycleModel
        ? "motos"
        : "carros";

    return `/${slugify(inventorySlugPreference, {
      lower: true,
    })}/${vehicleTypeSlug}/${slugify(regionsArr[0] ?? "brasil", {
      lower: true,
    })}/-${slugify(value, { lower: true })}`;
  }

  if (ref === "category" && value) {
    return `/${slugify(inventorySlugPreference, {
      lower: true,
    })}/carros/${slugify(regionsArr[0] ?? "brasil", {
      lower: true,
    })}/${FilterDefaultQueryKeys.Category}=${slugify(value, {
      lower: true,
    })}`;
  }

  if (ref === "style" && value) {
    return `/${slugify(inventorySlugPreference, {
      lower: true,
    })}/motos/${slugify(regionsArr[0] ?? "brasil", {
      lower: true,
    })}/${FilterDefaultQueryKeys.Style}=${slugify(value, {
      lower: true,
    })}`;
  }

  if (ref === "year" && value) {
    return `/${slugify(inventorySlugPreference, {
      lower: true,
    })}/carros/${slugify(regionsArr[0] ?? "brasil", {
      lower: true,
    })}/${FilterDefaultQueryKeys.ManufactureYear}=${slugify(value, {
      lower: true,
    })}`;
  }

  if (ref === "region") {
    const inventoryHasCar = (vehicleList ?? []).some((v) => v.type === "Car");
    const inventoryHasMotorcycle = (vehicleList ?? []).some(
      (v) => v.type === "Motocycle"
    );

    const vehicleTypeSlug =
      inventoryHasCar && inventoryHasMotorcycle
        ? "carros-e-motos"
        : inventoryHasMotorcycle
        ? "motos"
        : "carros";

    return `/${slugify(inventorySlugPreference, {
      lower: true,
    })}/${vehicleTypeSlug}/${slugify(value ?? "brasil", {
      lower: true,
    })}`;
  }
};
